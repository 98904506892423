<template>
  <div class="record">
    <div class="head">
      <div class="title d-flex align-center"><span></span>听课记录</div>
      <!-- <div class="btn d-flex align-center cursor">
        <img src="../../assets/img/del_w.png" alt="" />清空记录
      </div> -->
    </div>
    <ul class="list" v-if="coursesList != ''">
      <li
        class="item d-flex align-center"
        v-for="(item, index) in coursesList"
        :key="index"
      >
        <div class="order">
          <img src="../../assets/img/live_head.png" alt="" />
        </div>

        <div class="detail d-flex">
          <div class="detail-name">
            <div class="detail-title">
              {{ item.lessonName }}
            </div>
            <div class="detail-time">{{ item.updateTime }}</div>
          </div>
          <div
            class="detail-btn d-flex align-center justify-center cursor"
          >
            {{ item.studytime}}
          </div>
          <!-- <div
            class="detail-btn d-flex align-center justify-center cursor"
            :style="btn"
            @click="goLive"
          >
            听课学习
          </div> -->
        </div>
      </li>
    </ul>
    <v-pagination
      v-show="total > display"
      :total="total"
      :current-page="current"
      :display="display"
      @pagechange="pagechange"
    ></v-pagination>
    <div
      v-if="coursesList == '' && isReady"
      class="kong d-flex align-center flex-column justify-center"
    >
      <img class="kong-img" src="../../assets/img/kong.png" alt="" />
      <div class="kong-txt">暂无课程</div>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/pagination";
export default {
  data() {
    return {
      btn: {
        backgroundImage: "url(" + require("../../assets/img/go_btn.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%"
      },
      total: 0, // 记录总条数
      display: 8, // 每页显示条数
      current: 1, // 当前的页数
      coursesList: "",
      isReady: false
    };
  },
  components: {
    "v-pagination": pagination
  },
  mounted() {
    this.getCourseslist();
  },
  methods: {
    goLive() {
      location.href = "./html/live.html?a=" + "asdasd";
    },
    pagechange(currentPage) {
      console.log(currentPage); //该参数就是当前点击的页码数
      // ajax请求, 向后台发送 currentPage, 来获取对应的数据
      this.current = currentPage;
      this.getCourseslist();
      const currentY =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollAnimation(currentY, 0);
    },
    scrollAnimation(currentY, targetY) {
      // 获取当前位置方法
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY;
      let _currentY = currentY;
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10);
        _currentY += dist;
        window.scrollTo(_currentY, currentY);
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY);
        } else {
          window.scrollTo(_currentY, targetY);
        }
      }, 1);
    },
    async getCourseslist(params) {
      let _this = this;
      let data = {
        pageNum: _this.current,
        pageSize: _this.display
      }
      // let data = _this.id
      const res = await this.$ajaxRequest('post', 'queryStudyHistory', data)
      // console.log(res);
      if (res.code == 200) {
        let list = res.data.list
        for (let i in list) {
          var date = new Date(list[i].studytime) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          var h = parseInt(date / (1000 * 60 * 60))
          var m = parseInt((date - (h * 60 * 60 * 1000)) / (1000 * 60));
          var s = parseInt((date - (h * 60 * 60 * 1000) - (m * 60 * 1000)) / (1000));
          list[i].studytime = h + "时" + m + "分" + s + "秒"
        }
        console.log(list)
        _this.coursesList = list;
        _this.total = res.data.totalRows;
        // // this.tabList = res.data;
        _this.isReady = true;
      }

    },
    // getCourseslist() {
    //   let _this = this;
    //   _this.$api
    //     .queryStudyHistory({
    //       start: _this.current - 1,
    //       limit: _this.display
    //     })
    //     .then(res => {
    //       console.log(res);
    //       _this.coursesList = res.data;
    //       _this.total = res.count;
    //       // this.tabList = res.data;
    //       _this.isReady = true;
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // }
    // clearStudyHistory() {
    //   let _this = this;
    //   _this.$api
    //     .clearStudyHistory({
    //     })
    //     .then(res => {
    //       console.log(res);
    //       _this.coursesList = res.data;
    //       // _this.total = res.count;
    //       // this.tabList = res.data;
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
  }
};
</script>

<style lang="less" scoped>
.kong {
  margin-top: 50px;
  .kong-img {
    height: 210px;
    width: 280px;
  }
  .kong-txt {
    font-size: 20px;
    color: #999999;
    margin-top: 30px;
  }
}
.record {
  width: 1000px;
  height: 1027px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  padding: 0px 36px 36px 36px;
  box-sizing: border-box;
  .head {
    border-bottom: 1px solid #e7e8ef;
    width: 100%;
    padding: 33px 0 14px 0;
    box-sizing: border-box;
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #444444;
      span {
        width: 4px;
        height: 24px;
        background: #5f2eff;
        border-radius: 2px;
        margin-right: 12px;
        display: inline-block;
      }
    }
    .btn {
      font-size: 16px;
      color: #999999;
      margin-left: auto;
      width: 90px;
      img {
        height: 16px;
        width: 16px;
        margin-right: 9px;
      }
    }
  }

  .list {

    .item {
      height: 96px;
      .order {
        width: 16px;
        height: 16px;
        position: relative;
        margin-left: 16px;
        margin-right: 30px;
        img {
          position: relative;
        }
        &::before {
          content: "";
          display: block;
          height: 96px;
          width: 2px;
          background: #f4f0ff;
          position: absolute;
          top: 8px;
          left: 7px;
        }
      }

      .detail {
        position: relative;
        padding: 25px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #f6f9ff;
        width: 100%;
        .detail-name {
          .detail-title {
            font-size: 20px;
            color: #333333;
          }
          .detail-time {
            font-size: 14px;
            color: #999999;
          }
        }
        .detail-btn {
          width: 104px;
          height: 42px;
          font-size: 16px;
          // color: #ffffff;
          margin-left: auto;
        }
      }
    }
    :last-child {
      .order::before {
        display: none;
      }
    }
  }
}
</style>
